<template>

  <div v-if="$can('read', abilityRequired)">

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <!-- Pagination -->
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              :disabled="this.$store.state.postorders.fetchingList"
              @click="refreshList"
            >
              <feather-icon icon="RefreshCcwIcon" /><span class="text-nowrap ml-1">Refresh</span>
            </b-button>
            <b-button
              v-if="$can('create', abilityRequired)"
              class="ml-2"
              variant="primary"
              :to="{ name: 'apps-postorders-manage' }"
            >
              <feather-icon icon="PlusIcon" /><span class="text-nowrap ml-1">Add Post Order</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mt-1"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <!-- Table -->
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchListItems"
        responsive
        :fields="tableColumns"
        primary-key="SK"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
      >

        <!-- Column: title-->
        <template #cell(title)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name: 'apps-postorders-manage', params: { SK: data.item.SK } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }}
            </b-link>
          </div>
        </template>
      </b-table>
      <div
        v-if="$store.state.postorders.fetchingList"
        class="d-flex justify-content-center"
      >
        <b-spinner label="Loading..." />
      </div>
      <!-- Pagination -->
      <div class="mx-2 mb-2 mt-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BPagination, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import store from '@/store'
// import { ref, watch, onUnmounted } from '@vue/composition-api'
import { ref, onUnmounted } from '@vue/composition-api'
// import { useRouter } from '@core/utils/utils'
import useList from './useList'
import storeModule from '../storeModule'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BSpinner,

    vSelect,
  },
  setup() {
    const abilityRequired = 'postorders'

    // Get the current user's data
    const userData = getUserData()

    // Register module
    const REPORT_APP_STORE_MODULE_NAME = 'app-postorders'
    if (!store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.registerModule(REPORT_APP_STORE_MODULE_NAME, storeModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORT_APP_STORE_MODULE_NAME)
    })

    // Setup other vars
    const isAddNewSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Unapproved', value: false },
      { label: 'Approved', value: true },
    ]

    const {
      fetchListItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      theList,
      theListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,

      clientList,
    } = useList(abilityRequired, REPORT_APP_STORE_MODULE_NAME)

    return {
      abilityRequired,
      userData,
      // Sidebar
      isAddNewSidebarActive,

      fetchListItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      theList,
      theListComplete,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isBusy,
      isSortDirDesc,
      refListTable,
      refetchData,

      statusOptions,
      clientList,
    }
  },
  created() {
    this.$store.state.postorders.fetchingList = true
  },
  methods: {
    refreshList() {
      this.$store.state.postorders.fetchingList = true
      this.refetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
